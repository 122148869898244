export const CONTACT_SUPPORT_URL =
  'https://support.junglescout.com/hc/requests/new'
export const LAUNCH_ROOT_URL = '/launch'
export const MARKET_INSIGHTS_URL = '/market-insights'
export const PRODUCT_COSTS_AND_SETTINGS_URL = '/product-costs-and-settings'
export const PRODUCT_INSIGHTS_URL = '/product-insights'
export const SALES_ANALYTICS_URL = '/sales-analytics'
export const ADVERTISING_URL = '/advertising'
export const INVENTORY = '/inventory'
export const INVENTORY_REIMBURSEMENTS_URL = '/inventory-reimbursements'

export const LAUNCH_BASE_URLS = {
  reviewAutomation: `${LAUNCH_ROOT_URL}/review-automation`
}

export const SALES_ANALYTICS_URLS = {
  expenses: `${SALES_ANALYTICS_URL}/other-transactions`,
  profitAndLoss: `${SALES_ANALYTICS_URL}/profit-loss`,
  profitOverview: `${SALES_ANALYTICS_URL}/profit-overview`
}
// tab idendifiers 't=1' and 't=2' end up in the browser
// URL and user switching tabs is actually not changing
// the tab query param
export const OTHER_TRANSACTIONS_TABS = {
  other_expenses: `#${SALES_ANALYTICS_URLS.expenses}?t=1`,
  inbound_shipping: `#${SALES_ANALYTICS_URLS.expenses}?t=2`
}

export const INVENTORY_URLS = {
  inventoryForecast: `${INVENTORY}/inventory-forecast`
}

export const MARKET_INSIGHTS_URLS = {
  segmentManager: `${MARKET_INSIGHTS_URL}/segments`,
  newSegment: `${MARKET_INSIGHTS_URL}/segments/new`,
  newSegmentLanding: `${MARKET_INSIGHTS_URL}/segments/new/search`,
  editSegment: `${MARKET_INSIGHTS_URL}/segments/:id/edit`,
  copySegment: `${MARKET_INSIGHTS_URL}/segments/:id/copy`,
  shareAndTrendsBase: `${MARKET_INSIGHTS_URL}/share-trends`,
  shareAndTrends: `${MARKET_INSIGHTS_URL}/share-trends/:id`,
  asinExplorer: `${MARKET_INSIGHTS_URL}/share-trends/:id/asin-explorer/:country/:asin`
}

export const ADVERTISING_URLS = {
  analytics: `${ADVERTISING_URL}/analytics`
}

export const SETTINGS_URLS = {
  allSettings: '/account',
  accountInfo: '/account/info',
  billing: '/account/billing',
  subscriptions: '/account/subscriptions',
  emailSettings: '/account/email-settings',
  alertSettings: '/account/alert-settings',
  amazonSettings: '/account/amazon-settings',
  team: '/account/team-settings',
  languages: '/account/languages'
}
export const COMPETITIVE_INTELLIGENCE_URL = '/competitive-intelligence'
export const COMPETITIVE_INTELLIGENCE_MARKET_URL =
  '/competitive-intelligence/segment'

export const DASHBOARD_URL = '/dashboard'
export const SETTINGS_URL = '/account/amazon-settings'
export const TEAM_SETTINGS_URL = '/account/team-settings'
export const CHECKOUT_URLS = {
  registrations: '/registrations',
  checkoutLogin: '/checkout',
  checkoutPayment: '/checkout/payment'
}

export const SELLER_TOOLS_URL = '/seller-tools'

export const PRODUCT_INSIGHTS_URLS = {
  asinTracker: PRODUCT_INSIGHTS_URL,
  asinExplorer: `${PRODUCT_INSIGHTS_URL}/asin-explorer/:country/:asin`
}

export const PPC_SYNC_RETURN_URL = '/ppc-sync-callback'

export const SALES_ESTIMATOR_URL = '/toolbox/sales-estimator'
export const LISTING_GRADER_BASE = '/toolbox/listing-grader'
export const REVIEW_ANALYSIS_BASE = '/toolbox/review-analysis'

export const SELLING_PARTNER_SYNC_CALLBACK_URL = '/mws-sync-callback'

export const KEYWORD_SCOUT_URL = '/keyword'
export const KEYWORD_LISTS_URL = '/keyword/lists'
export const KEYWORD_SEARCH_VOLUME_URL =
  'api/keyword_engine/historical_search_volume'
export const LISTING_BUILDER_BASE = '/keyword/listings'
export const RANK_TRACKER_V2_BASE = '/keyword/rank-tracker'

export const SHARE_OF_VOICE_URL = '/share-of-voice'

export const ACADEMY_URL = '/academy'
export const EXTENSION_URL = '/extension'
export const ADMIN_URL = '/admin'

export const DEVELOPER_URL = '/developer'

export const PRODUCT_RESEARCH_URLS = {
  database: '/database',
  tracker: '/tracker',
  opportunityFinder: '/opportunity-finder',
  categoryTrends: '/category-trends'
}

export const SUPPLIER_URLS = {
  supplier: '/supplier',
  results: '/supplier/results',
  view: '/supplier/view',
  tracker: '/supplier/supplier-tracker'
}

export const SEGMENT_PAGE_TRACK_ROUTS_MAPPING = {
  [`${DASHBOARD_URL}#connectMWS`]: {
    name: 'Dashboard from Extension Dropdown',
    category: 'Home Dashboard'
  },
  [DASHBOARD_URL]: {
    name: 'Dashboard Page',
    category: 'Home Dashboard'
  },
  [MARKET_INSIGHTS_URLS.segmentManager]: {
    name: 'Segment Manager',
    category: 'Market Insights'
  },
  [MARKET_INSIGHTS_URLS.shareAndTrendsBase]: {
    name: 'Share and Trends',
    category: 'Market Insights'
  },
  [PRODUCT_INSIGHTS_URL]: {
    name: 'Product Insights',
    category: 'Product Insights'
  },
  [SHARE_OF_VOICE_URL]: {
    name: 'Share of Voice',
    category: 'Share of Voice'
  },
  [SALES_ESTIMATOR_URL]: {
    name: 'Sales Estimator',
    category: 'Toolbox'
  },
  [LISTING_GRADER_BASE]: {
    name: 'Listing Grader',
    category: 'Toolbox'
  },
  [REVIEW_ANALYSIS_BASE]: {
    name: 'Review Analysis',
    category: 'Toolbox'
  },
  [PRODUCT_RESEARCH_URLS.database]: {
    name: 'Product Database',
    category: 'Product Research'
  },
  [PRODUCT_RESEARCH_URLS.tracker]: {
    name: 'Product Tracker',
    category: 'Product Research'
  },
  [PRODUCT_RESEARCH_URLS.opportunityFinder]: {
    name: 'Opportunity Finder',
    category: 'Product Research'
  },
  [PRODUCT_RESEARCH_URLS.categoryTrends]: {
    name: 'Category Trends',
    category: 'Product Research'
  },
  [SUPPLIER_URLS.supplier]: {
    name: 'Supplier Database',
    category: 'Suppliers'
  },
  [SUPPLIER_URLS.results]: {
    name: 'Supplier Database',
    category: 'Suppliers'
  },
  [SUPPLIER_URLS.view]: {
    name: 'Supplier Database',
    category: 'Suppliers'
  },
  [SUPPLIER_URLS.tracker]: {
    name: 'Supplier Tracker',
    category: 'Suppliers'
  },
  [KEYWORD_SCOUT_URL]: {
    name: 'Keyword Scout',
    category: 'Keywords'
  },
  [KEYWORD_LISTS_URL]: {
    name: 'Keyword Lists',
    category: 'Keywords'
  },
  [LISTING_BUILDER_BASE]: {
    name: 'Listing Builder',
    category: 'Keywords'
  },
  [RANK_TRACKER_V2_BASE]: {
    name: 'Rank Tracker',
    category: 'Keywords'
  },
  [LAUNCH_BASE_URLS.reviewAutomation]: {
    name: 'Review Automation',
    category: 'Marketing'
  },
  [SALES_ANALYTICS_URLS.profitOverview]: {
    name: 'Profit Overview',
    category: 'Sales Analytics'
  },
  [SALES_ANALYTICS_URLS.profitAndLoss]: {
    name: 'P&L Statement',
    category: 'Sales Analytics'
  },
  [SALES_ANALYTICS_URLS.expenses]: {
    name: 'Other Transactions',
    category: 'Sales Analytics'
  },
  [ADVERTISING_URLS.analytics]: {
    name: 'Ads Analytics',
    category: 'Advertising'
  },
  [INVENTORY_URLS.inventoryForecast]: {
    name: 'Inventory Manager',
    category: 'Manage Products'
  },
  [PRODUCT_COSTS_AND_SETTINGS_URL]: {
    name: 'Product Costs & Settings',
    category: 'Manage Products'
  },
  [INVENTORY_REIMBURSEMENTS_URL]: {
    name: 'Inventory Reimbursements',
    category: 'Inventory Reimbursements'
  },
  [ACADEMY_URL]: {
    name: 'Academy',
    category: 'Academy'
  },
  [EXTENSION_URL]: {
    name: 'The Extension',
    category: 'The Extension'
  },
  [ADMIN_URL]: {
    name: 'Admin Panel',
    category: 'Admin Panel'
  },
  [SETTINGS_URLS.accountInfo]: {
    name: 'Account Information',
    category: 'Settings'
  },
  [SETTINGS_URLS.billing]: {
    name: 'Billing Information',
    category: 'Settings'
  },
  [SETTINGS_URLS.subscriptions]: {
    name: 'Subscriptions',
    category: 'Settings'
  },
  [SETTINGS_URLS.emailSettings]: {
    name: 'Email Settings',
    category: 'Settings'
  },
  [SETTINGS_URLS.alertSettings]: {
    name: 'Alert Settings',
    category: 'Settings'
  },
  [SETTINGS_URLS.amazonSettings]: {
    name: 'Amazon Settings',
    category: 'Settings'
  },
  [TEAM_SETTINGS_URL]: {
    name: 'User & Team Management',
    category: 'Settings'
  },
  [SETTINGS_URLS.languages]: {
    name: 'Languages',
    category: 'Settings'
  },
  [CHECKOUT_URLS.registrations]: {
    name: 'Checkout Page',
    category: 'Checkout'
  },
  [CHECKOUT_URLS.checkoutLogin]: {
    name: 'Checkout Login',
    category: 'Checkout'
  },
  [CHECKOUT_URLS.checkoutPayment]: {
    name: 'Checkout Payment',
    category: 'Checkout'
  },
  [COMPETITIVE_INTELLIGENCE_MARKET_URL]: {
    name: 'Market',
    category: 'Competitive Intelligence'
  },
  [COMPETITIVE_INTELLIGENCE_URL]: {
    name: 'Competitive Intelligence',
    category: 'Competitive Intelligence'
  },
  [DEVELOPER_URL]: {
    name: 'Developer',
    category: 'Developer'
  }
}

export const PAGE_NAMES = t => ({
  marketInsights: t('sidebar:marketInsights.header', 'Market Insights'),
  segmentManager: t(
    'sidebar:marketInsights.child.segmentManager',
    'Segment Manager'
  ),
  shareAndTrends: t(
    'sidebar:marketInsights.child.shareAndTrends',
    'Share and Trends'
  ),
  productInsights: t('sidebar:productInsights.header', 'Product Insights'),
  shareOfVoice: t('sidebar:shareOfVoice.header', 'Share of Voice'),
  competitiveIntelligence: t(
    'sidebar:competitiveIntelligence.header',
    'Competitive Intelligence'
  ),
  dashboard: t('sidebar:dashboard.header', 'Home Dashboard'),
  toolbox: t('sidebar:toolbox.header', 'Toolbox'),
  salesEstimator: t('sidebar:toolbox.child.salesEstimator', 'Sales Estimator'),
  listingGrader: t('sidebar:toolbox.child.listingGrader', 'Listing Grader'),
  reviewAnalysis: t('sidebar:toolbox.child.reviewAnalysis', 'Review Analysis'),
  finder: t('sidebar:finder.Header', 'Product Research'),
  db: t('sidebar:finder.child.db', 'Product Database'),
  developer: t('sidebar:developer.header', 'Developer'),
  tracker: t('sidebar:finder.child.tracker', 'Product Tracker'),
  opFinder: t('sidebar:finder.child.opfinder', 'Opportunity Finder'),
  hunter: t('sidebar:finder.child.hunter', 'Niche Hunter'),
  categoryTrends: t('sidebar:finder.child.categoryTrends', 'Category Trends'),
  supplier: t('sidebar:supplier.header', 'Suppliers'),
  supplierDb: t('sidebar:supplier.child.db', 'Supplier Database'),
  supplierTracker: t('sidebar:supplier.child.tracker', 'Supplier Tracker'),
  keyword: t('sidebar:keyword.header', 'Keywords'),
  keywordScout: t('sidebar:keyword.child.scout', 'Keyword Scout'),
  keywordList: t('sidebar:keyword.child.list', 'Keyword Lists'),
  listingBuilder: t('sidebar:keyword.child.builder', 'Listing Builder'),
  rankTracker: t('sidebar:keyword.child.rank', 'Rank Tracker'),
  marketing: t('sidebar:launch.header', 'Marketing'),
  reviewAutomation: t(
    'sidebar:launch.child.review_automation',
    'Review Automation'
  ),
  analytics: t('sidebar:analytics.header', 'Sales Analytics'),
  profit: t('sidebar:analytics.child.profit', 'Profit Overview'),
  advertising: t('sidebar:advertising.header', 'Advertising'),
  adsAnalytics: t('sidebar:advertising.child.analytics', 'Ads Analytics'),
  profitAndLoss: t('sidebar:analytics.child.profitAndLoss', 'P&L Statement'),
  expenses: t('sidebar:analytics.child.expenses', 'Other Transactions'),
  manageProd: t('sidebar:prodSetting.header', 'Manage Products'),
  inventory: t('sidebar:inventory.manager', 'Inventory Manager'),
  fbaReimbursements: t('sidebar:fbaReimbursements', 'FBA Reimbursements'),
  costsAndSettings: t(
    'sidebar:prodSetting.child.costsAndSettings',
    'Product Costs & Settings'
  ),
  academy: t('sidebar:academyHeader', 'Academy'),
  extension: t('sidebar:extensionHeader', 'The Extension'),
  admin: t('sidebar:adminHeader', 'Admin Panel')
})
